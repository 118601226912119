<template>
  <div>
  <v-dialog
      v-model="activo"
      width="850"
      scrollable
      :persistent="activo"
    >
      <v-card :loading="load">
        <v-card-title class="">
          <div style="font-size: 20px" v-text="`${this.nuevo ? 'Nuevo' : 'Editar'} objetivo`"></div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-4 py-3">
          <v-form @submit.prevent="guardarCambios()">
            <v-row class="pt-2">
              <v-col cols="12" sm="6" md="4" class="pt-1">
                Categoría
                <v-text-field
                  v-model="filtro.categoria_nombre"
                  hide-details
                  outlined
                  dense
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pt-1">
                Fecha Desde
                <FechaPickerVue v-model="filtro.fecha_desde"
                  hideDetails outline :clearable="nuevo"
                  :fecha_minima="moment(fechaMinima).format('DD/MM/YYYY')"
                  :disabled="!nuevo && !datos.controlarFechas"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" class="pt-1">
                Fecha Hasta
                <FechaPickerVue v-model="filtro.fecha_hasta"
                  hideDetails outline :fecha_minima="moment(fechaMinimaHasta).format('DD/MM/YYYY')"
                  :clearable="nuevo && !datos.controlarFechas"
                  :disabled="nuevo ? false : ( !datos.controlarFechas ? (!datos.fecha_hasta? false : (limitFecInf > new Date(datos.fecha_hasta_sf))) : false)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2" class="pt-1">
                Porcentaje
                <v-text-field
                  v-model="filtro.porcentaje"
                  type="number"
                  suffix="%"
                  hide-details
                  outlined
                  dense
                  :readonly="!nuevo && !datos.controlarFechas"
                  :filled="!nuevo && !datos.controlarFechas"
                  @change="controlarPorcentaje()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-1">
                Canal
                <v-autocomplete
                  v-model="filtro.canal_id"
                  item-text="nombre"
                  item-value="id"
                  :items="canales"
                  hide-details
                  outlined
                  dense
                  :clearable="nuevo"
                  :readonly="!nuevo && !datos.controlarFechas"
                  :filled="!nuevo && !datos.controlarFechas"
                  @change="getTiposObjetivos()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-1">
                Tipo de Objetivo
                <v-autocomplete
                  v-model="filtro.tipo_objetivo_id"
                  item-text="tipo_objetivo_nombre"
                  item-value="tipo_objetivo_id"
                  :items="tiposObjetivosSelect"
                  hide-details
                  outlined
                  dense
                  :clearable="nuevo"
                  :readonly="!nuevo && !datos.controlarFechas"
                  :filled="!nuevo && !datos.controlarFechas"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-1">
                Artículos Particulares
                <v-checkbox
                  v-model="filtro.tiene_articulos"
                  class="ma-0 pb-1"
                  :false-value="0"
                  :true-value="1"
                  dense
                  hide-details
                  :readonly="!nuevo && !datos.controlarFechas"
                  :filled="!nuevo && !datos.controlarFechas"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="filtro.tiene_articulos || filtro.tiene_articulos == 1">
              <v-col cols="12" sm="6" md="3" class="pt-1" v-if="nuevo || datos.controlarFechas">
                Cód. Artículo
                <v-text-field
                  v-model="articulo_codigo"
                  type="number"
                  hide-details
                  outlined
                  dense
                  clearable
                  @blur="agregarLista()"
                >
                  <template v-slot:append-outer>
                    <v-btn icon small>
                      <v-icon
                        color="info"
                        title="Buscar Articulo"
                        small
                        @click="abrirModal()"
                      >
                        fas fa-search
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="9" class="d-flex justify-end" v-if="nuevo || datos.controlarFechas">
                <SubirArchivosVue
                  :datos="objArchivo"
                  @accion="recibirCodigos"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-data-table
                  class="cebra elevation-2"
                  :headers="nuevo || datos.controlarFechas || !datos.fecha_hasta? header : (!filtro.fecha_hasta? header : header2)"
                  :items="articulos"
                  :loading="load"
                  :search="search"
                  dense
                  item-key="articulo_codigo"
                  :footer-props="{'items-per-page-options':[5, 10, 15, 50, 100]}"
                >
                  <!-- Buscador -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3" md="2">
                        <SearchDataTable
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.accion`]="{ item }">
                      <v-icon
                        color="error"
                        title="Quitar"
                        class="mr-2"
                        small
                        @click="eliminarArticulo(item)"
                      >
                        fas fa-times
                      </v-icon>
                  </template>
                  <!-- No hay datos -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay artículos agregados
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!-- BOTONES -->
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="mr-2"
            color="secondary"
            @click="activo = false"
          >{{ nuevo || datos.controlarFechas || !datos.fecha_hasta ? 'Cancelar' : 'Volver' }}</v-btn>
          <BtnConfirmarVue
            v-if="nuevo || datos.controlarFechas || !datos.fecha_hasta || limitFecInf <= new Date(datos.fecha_hasta_sf)"
            :texto="`Está por ${nuevo? `grabar un nuevo` : `editar el`} objetivo para la categoría ${filtro.categoria_nombre}. ¿Confirma esta accion?`"
            :nombre="nuevo ? 'GRABAR' : 'GUARDAR CAMBIOS'"
            :loading="load"
            @action="guardarCambios()"
          />
        </v-card-actions>
        <ModalSeleccionarArticuloVue 
          v-model="objModal.dialogArt"
          :datos="objModal"
          @seleccionarArticulo="cargarNombreArticulo"
        />
      </v-card>
    </v-dialog>
  </div>
  </template>
  
  <script>
  import moment from 'moment';
  import BtnConfirmarVue from '../../util/BtnConfirmar.vue';
  import SubirArchivosVue from '../../util/SubirArchivos.vue';
  import FechaPickerVue from '../../util/FechaPicker.vue';
  import SearchDataTable from '../../util/SearchDataTable.vue';
  import { order_list_by } from '../../../util/utils';
  import ModalSeleccionarArticuloVue from '../../promociones/ModalSeleccionarArticulo.vue';
  
  export default {
    name: 'ModalNvoEditarObjCat',
    props:{
      value: Boolean,
      datos:{
        type: Object,
        default: {}
      }  
    },
    computed:{
      activo:{
        get(){
          return this.datos.activo;
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    data(){
      return{
        load: false,
        search:'',
        moment: moment,
        nuevo: false,
        header: [
          {text: 'Código', value: 'articulo_codigo', align: 'end'},
          {text: 'Descripción', value: 'articulo_nombre'},
          {text: 'Categoría', value: 'categoria_nombre'},
          {text: 'Acción', value: 'accion', filterable: false, sortable: false, align: 'center'},
        ],
        header2: [
          {text: 'Código', value: 'articulo_codigo', align: 'end'},
          {text: 'Descripción', value: 'articulo_nombre'},
          {text: 'Categoría', value: 'categoria_nombre'},
        ],
        objetivosCategorias: [],
        filtro:{
          categoria_codigo    : null,
          categoria_nombre    : '',
          canal_id            : null,
          canal_nombre        : "",
          fecha_desde         : "",
          fecha_hasta         : '',
          id                  : 0,
          porcentaje          : 100,
          tiene_articulos     : false,
          tipo_objetivo_id    : null,
          tipo_objetivo_nombre: '',
        },
        tiposObjetivos: [],
        tiposObjetivosSelect: [],
        canales: [],
        articulo_codigo: null,
        objModal:{
          dialogArt: false,
          categoria_codigo: 0,
        },
        articulos: [],
        fechaMinima: `${(new Date).getFullYear()}-${1<=((new Date).getMonth() + 1)&&((new Date).getMonth() + 1)<=9 ? "0"+((new Date).getMonth() + 1) : (new Date).getMonth() + 1}-01`,
        fechaMinimaHasta: '',
        bandFecha: 0,
        limitFecInf: new Date((new Date).getFullYear(),(new Date).getMonth(),1), //primer día del mes actual
        limitFecSup: new Date((new Date((new Date).getFullYear(), (new Date).getMonth() + 1, 1)) - 1), //último día del mes actual
        objArchivo:{
          tipos: ['.txt', '.xls', '.xlsx'],
          cantidad: 1,
          color: 'info',
        },
      }
    },
    created(){
    },
    methods:{
      async init(){
        if(this.filtro.id == 0){
          this.nuevo = true;
        }else this.nuevo = false;
        
        this.load = true;
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('categorias/iniciarFormObjetivos');
        this.$store.state.loading = false;
        this.load = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error'  });
        }
        this.canales        = res.canales;
        this.tiposObjetivos = res.tipos_objetivos; 
        order_list_by(this.canales, 'nombre');
        order_list_by(this.tiposObjetivos, 'tipo_objetivo_nombre');
        //trae si es que es para editar
        this.getTiposObjetivos();

        if(this.filtro.tiene_articulos == 1){
          this.load = true;
          this.$store.state.loading = true;
          const res1 = await this.$store.dispatch('categorias/getTieneArticulos',{ categoria_codigo: this.filtro.categoria_codigo, comision_categoria_id: this.filtro.id });
          this.$store.state.loading = false;
          this.load = false;
          if(res1.resultado == 0){
            return this.$store.dispatch('show_snackbar', { text: res1.message, color: 'error'  });
          }
          this.articulos = res1.articulos
          order_list_by(this.articulos, 'articulo_codigo');
        }
      },
      controlarPorcentaje(){
        if(this.filtro.porcentaje < 0){
          this.filtro.porcentaje = 0;
        }
      },
      getTiposObjetivos(){
        if(this.filtro.canal_id){
          this.tiposObjetivosSelect = this.tiposObjetivos.filter(e => e.canal_id == this.filtro.canal_id);
        }else{
          this.tiposObjetivosSelect        = [];
          this.filtro.tipo_objetivo_id     = null;
          this.filtro.tipo_objetivo_nombre = null;
        }
      },
      abrirModal(){
        this.objModal.dialogArt = true;
        this.objModal.categoria_codigo = this.filtro.categoria_codigo;
      },
      cargarNombreArticulo(item){
        let nvoArticulo                   = JSON.parse(JSON.stringify(item));
        nvoArticulo.comision_categoria_id = this.filtro.id;
        nvoArticulo.id                    = 0;
        
        let pos = this.articulos.map(e => e.articulo_codigo).indexOf(item.articulo_codigo);
        if(pos == -1){
          this.articulos.push(nvoArticulo);
          order_list_by(this.articulos, 'articulo_codigo');
          return this.$store.dispatch('show_snackbar', { text: `El artículo ${item.articulo_codigo} se agregó existosamente.`, color: 'success', })
        }else return this.$store.dispatch('show_snackbar', { text: `El artículo ${item.articulo_codigo} ya se encuentra en la lista.`, color: 'info', })

      },
      async agregarLista(){
        if(this.articulo_codigo && this.articulo_codigo != 0){
          this.$store.state.loading = true; //obtenerArticuloPorCodigo
          let res = await this.$store.dispatch('promociones/obtenerArticuloPorCodigo2', { articulo_codigo: this.articulo_codigo, categoria_codigo: this.filtro.categoria_codigo });
          this.$store.state.loading = false;
          if(res.resultado == 0){
            return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
          }
          //find
          if(res.articulo.length == 1){
            this.cargarNombreArticulo(res.articulo[0]);
            this.articulo_codigo = null;
          }
          if(res.articulo.length == 0){
            this.abrirModal();
          }
        }else{
          this.abrirModal();
        }
      },
      eliminarArticulo(item){
        let pos = this.articulos.map(e => e.articulo_codigo).indexOf(item.articulo_codigo);
        if(pos != -1){
          this.articulos.splice(pos,1);
          order_list_by(this.articulos, 'articulo_codigo');
          return this.$store.dispatch('show_snackbar', { text: `El artículo ${item.articulo_codigo} se quitó existosamente.`, color: 'success', })
        }else return this.$store.dispatch('show_snackbar', { text: `El artículo ${item.articulo_codigo} no se encuentra en la lista.`, color: 'info', })
      },
      limpiar(){
        this.nuevo            = false;
        this.articulo_codigo  = null;
        this.articulos        = [];
        this.bandFecha        = 0;
        this.search           = '';
      },
      controlarFecha(){
        let [dia, mes, anio] = this.filtro.fecha_desde.split('/');
        this.fechaMinimaHasta = anio+'-'+mes+'-'+dia;
      },
      dividirArrayEnSubArrays(array, tamanioSubArray) {
        const resultado = [];
        for (let i = 0; i < array.length; i += tamanioSubArray) {
            resultado.push(array.slice(i, i + tamanioSubArray));
        }
        return resultado;
      },
      convertirArrayAcadenas(codigos){
        let arrayDivido = this.dividirArrayEnSubArrays(codigos, 1000);
        let arrayCadenas = [];
        arrayDivido.forEach(e => {
          arrayCadenas.push(e.join(', '));
        })
        return arrayCadenas;
      },
      async recibirCodigos(codigos){
        if(codigos.length > 0){
          let arrayCadenas = this.convertirArrayAcadenas(codigos);
          this.$store.state.loading = true; //obtenerArticuloPorCodigo
          let res = await this.$store.dispatch('categorias/cargaMasivaArticulosObjetivos', { 
            categoria_codigo: this.filtro.categoria_codigo, 
            codigos: arrayCadenas 
          });
          this.$store.state.loading = false;
          if(res.resultado == 0){
            return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
          }

          if(res.articulos.length > 0){
            let artRes = res.articulos;
            let artNoEncontrados = codigos.filter(e => !(artRes.some(obj2 => e == obj2.articulo_codigo)) );
            let articulosExistentes           = [];

            await artRes.forEach(e => { 

              let nvoArticulo                   = JSON.parse(JSON.stringify(e));
              nvoArticulo.comision_categoria_id = this.filtro.id;
              nvoArticulo.id                    = 0;

              let pos = this.articulos.map(elem => elem.articulo_codigo).indexOf(e.articulo_codigo);
              if(pos == -1){
                this.articulos.push(nvoArticulo);
              }else articulosExistentes.push(e);
            });
            if(articulosExistentes.length > 0){
              this.$store.dispatch('show_snackbar', { text: `Los siguientes artículos ya se encontraban en el listado: ${articulosExistentes.map(e => e.articulo_codigo).join(', ')}`, color: 'info', })                
            }
            
            if(artNoEncontrados.length > 0){ this.$store.dispatch('show_snackbar', { text: `Los siguientes códigos no corresponden a un artículo para esta categoría: ${artNoEncontrados.join(', ')}`, color: 'info', }) }
          } else this.$store.dispatch('show_snackbar', { text: 'Ninguno de los códigos del documento fue encontrado. No corresponden a Artículos para esta categoría.', color: 'info', })
        }
      },
      validarCampos(){
        let error = {}
        if( !this.filtro.categoria_codigo ){
          error.text = 'No es posible continuar porque no se determinó la Categoría.'
          error.color = 'warning'
          return error
        }
        if( !this.filtro.fecha_desde ){
          error.text = 'Debe completar el Fecha Desde.'
          error.color = 'warning'
          return error
        }
        if( (!this.filtro.porcentaje && this.filtro.porcentaje != 0) || this.filtro.porcentaje < 0 ){
          error.text = 'Debe ingresar un valor de porcentaje mayor a cero (0).'
          error.color = 'warning'
          return error
        }
        if( !this.filtro.canal_id ){
          error.text = 'Debe seleccionar un Canal.'
          error.color = 'warning'
          return error
        }
        if( (this.filtro.canal_id && this.filtro.canal_id != 4) && !this.filtro.tipo_objetivo_id ){
          error.text = 'Debe seleccionar un Tipo de Objetivo.'
          error.color = 'warning'
          return error
        }
        if( this.filtro.tiene_articulos == 1 && (!this.filtro.fecha_desde || !this.filtro.fecha_hasta)){
          error.text = 'Debe agregar rango de fechas.'
          error.color = 'warning'
          return error
        }
        if( this.filtro.tiene_articulos == 1 && this.articulos.length == 0 ){
          error.text = 'Debe agregar al listado al menos un articulo.'
          error.color = 'warning'
          return error
        }
        return error;
      },
      async guardarCambios(){
        let error = this.validarCampos();
        if(Object.keys(error).length != 0){
          return this.$store.dispatch('show_snackbar', error)
        }
        this.$store.state.loading = true;
        let res = await this.$store.dispatch('categorias/nuevoEditarObjetivo', { 
          datos:      this.filtro,
          articulos:  this.filtro.tiene_articulos == 1 && this.articulos.length > 0 ? this.articulos : [],
          actual:     this.datos.controlarFechas
        });
        this.$store.state.loading = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error', })
        }
        this.$store.dispatch('show_snackbar', { text: this.nuevo ? 'Objetivo creado correctamente.' : 'Objetivo actualizado correctamente.', color: 'success', })
        this.activo = false;
        this.$emit('actualizar');
      }
    },
    components:{
      FechaPickerVue, SearchDataTable, BtnConfirmarVue, ModalSeleccionarArticuloVue, SubirArchivosVue
    },
    watch:{
      activo: function(val){
        if(val){
          this.filtro = JSON.parse(JSON.stringify(this.datos));
          delete this.filtro.controlarFechas;
          this.controlarFecha();
          this.init();
        }else{
          this.limpiar();
        }
      },
      'filtro.fecha_desde': {
        handler: function(val){
          if(val){
            if(this.bandFecha == 1){
              let [dia, mes, anio] = val.split('/');
              this.fechaMinimaHasta = anio+'-'+mes+'-'+dia;
            }else this.bandFecha = 1;
          }else this.filtro.fecha_hasta = '';
        },
        deep: true
      }
    }
  }
  </script>
  
  <style>
  
  </style>